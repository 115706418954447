/* eslint-disable react-hooks/exhaustive-deps */
import { Group, Person, PersonType } from '@eagle/core-data-types';
import {
  AppliedFilter,
  AppliedFilterType,
  CacheDataTypes,
  entityGroup,
  ErrorPage,
  evaluate,
  FeatureIcons,
  FilterDataTypes,
  filterDeletedCache,
  FilterFieldNewProps,
  filterToQuery,
  FilterTypes,
  FILTER_OUT,
  FindItemsDeferredResult,
  getListResultDescription,
  ListPage,
  ListPageQuery,
  MiddleSpinner,
  NewFilter,
  NEW_FILTER_FLAG,
  PortalFeatureIcons,
  SAVED_FILTER_KEY,
  SearchResults,
  TRACK_PERSON_LIST_ROW_DEFAULT_TEMPLATE,
  T_MANY,
  T_ONE,
  useAuthenticated,
  useCustomRoutes,
  useDynamicModule,
  useFetchAllCache,
  useFlags,
  usePromise,
  useUiTemplate
} from '@eagle/react-common';
import Axios from 'axios';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const personListItemRenderer = (data: Person, template: unknown): JSX.Element => {
  return <>{evaluate(template, data)}</>;
};

export const PersonList: FC = () => {
  const { t } = useTranslation(['common', 'terms', 'track']);
  const { person: customRoutesPerson } = useCustomRoutes();
  const navigate = useNavigate();
  const { account, restClient } = useAuthenticated();
  const flags = useFlags();
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const { template, loaded: templateLoaded } = useUiTemplate('track-person-list-row', TRACK_PERSON_LIST_ROW_DEFAULT_TEMPLATE);

  const groupCache = useFetchAllCache(CacheDataTypes.GROUP);
  const [group, groupError, groupState] = usePromise(
    filterDeletedCache<Group>(groupCache),
    [account, groupCache],
  );

  const personTypesCache = useFetchAllCache(CacheDataTypes.PERSON_TYPE);
  const [personsTypes, personTypesError, personTypesState] = usePromise(
    filterDeletedCache<PersonType>(personTypesCache),
    [personTypesCache],
  );

  const findPersons = ({ filters, pagination, search }: ListPageQuery): FindItemsDeferredResult<Person> => {
    const cancelToken = Axios.CancelToken.source();

    return {
      cancel: () => cancelToken.cancel(),
      promise: restClient.person.getAllV1Paged({
        ...pagination,
        ...(search ? { search } : {}),
        filter: { ...filterToQuery(filters), ...FILTER_OUT.deleted },
        sort: !search ? JSON.stringify({ display: 'asc' }) : undefined,
      }, { cancelToken: cancelToken.token }).then((response) => {
        const matchCount = response.count;
        const resultDescription = getListResultDescription({ count: matchCount, entityKey: 'terms:person', filters, search, t });

        return {
          result: {
            results: response.entities,
            itemCount: matchCount,
          },
          resultDescription,
        };
      }),
    };
  };

  const navigateToPerson = (person: Person): void => {
    navigate(`/${customRoutesPerson}/${encodeURIComponent(person._id)}`);
  };

  const buildHref = (item: Person): string => {
    return `/${customRoutesPerson}/${encodeURIComponent(item._id)}`;
  };

  const renderFilterContent = (
    filters: AppliedFilter<AppliedFilterType>[],
    setFilterOpen: (value: boolean) => void,
    onFiltersChanged: (filters: AppliedFilter<AppliedFilterType>[]) => unknown,
  ): JSX.Element => {
    const filterFields: FilterFieldNewProps[] = [
      {
        apiUrl: '/api/v1/person',
        attributes: {
          typeCache: personTypesCache,
          typePath: FilterTypes.PERSON_TYPE,
        },
        dataType: FilterDataTypes.API,
        fieldLabel: t('common:component.filter.labels.select-a-person'),
        propertyLabel: t('terms:person', { count: T_MANY }),
        typePropertyName: FilterTypes.ID,
      },
      {
        dataType: FilterDataTypes.CACHE,
        entityCache: groupCache,
        fieldLabel: t('common:component.filter.labels.select-a-group'),
        propertyLabel: t('common:terms.group', { count: T_MANY }),
        typePropertyName: FilterTypes.GROUP,
      },
    ];

    return (
      <NewFilter
        filterFields={filterFields}
        filters={filters}
        onCloseClicked={() => setFilterOpen(false)}
        onFiltersChanged={onFiltersChanged}
        savedFilterKey={SAVED_FILTER_KEY}
        storageKey="persons"
        data-testid="persons-new-filter"
      />
    );
  };

  if (personTypesState === 'pending' || groupState === 'pending' || !moduleLoaded || !templateLoaded) return <MiddleSpinner />;
  if (groupError) return <ErrorPage error={groupError} />;
  if (personTypesError) return <ErrorPage error={personTypesError} />;

  return (
    <ListPage<Person>
      data-testid='person-list'
      filterFields={[
        {
          entityTypes: personsTypes ?? [],
          propertyLabel: t('common:common.labels.type'),
          typePropertyName: FilterTypes.PERSON_TYPE,
        },
        {
          entityTypes: group ? entityGroup(group) : [],
          propertyLabel: t('common:terms.group', { count: T_ONE }),
          typePropertyName: FilterTypes.GROUP,
        },
      ]}
      icon={module?.PersonIcon && <module.PersonIcon />}
      navigateToEntity={navigateToPerson}
      onQueryChanged={findPersons}
      renderContent={(highlightIndex, isLoading, items, text) => (
        <SearchResults<Person>
          data-testid='person-search-results'
          buildHref={buildHref}
          handleFormatListItem={(item) => personListItemRenderer(item, template)}
          highlightIndex={highlightIndex}
          initialInstructions={t('common:component.lookup.hint.initial')}
          isLoading={isLoading}
          noResultsInstructions={t('common:page.people-list.hint.none')}
          searchResults={items}
          selectedItem={null}
          text={text}
        />
      )}
      renderFilterContent={flags[NEW_FILTER_FLAG] ? renderFilterContent : undefined}
      storageKey="persons"
      title={t('terms:person', { count: T_MANY })}
    />
  );
};
